///////// Library Importation ///////

// React Hook 
import React, { useContext, useEffect, useRef, useState } from "react";

// Personalised Component Importation 
import Navbar from "../Components/Navbar"
import Footer from "./../Components/Footer";

// Material UI Component Importation
import Container from "@mui/material/Container";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Card, CardContent, CardMedia } from "@mui/material";
// Material UI Icon Importation
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Animation Motion Component and Personalised Animation Importation 
import { motion } from "framer-motion";
import { fadeIn } from "../Components/variants";

// Personalised Style Sheet Importation
import "./../Styles/Home.css"
import illustration from "./../Images/other/illustration-02.png"

// Homepage Text and Icon, Image Content
import HomeContent from "../Contents/Home";

import LinkedBtn from "../Components/LinkedBtn";

// CSV Context that contain the data
import { CSVContext } from "../Contexts/CSVContext";

const CardChoise = (title, icon, texte) => {
    return (
        <Card sx={{ backgroundColor: "#130e21", height: "100% ", padding: "0 1vw 5vh 1vw", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                <Grid2 container spacing={2}>
                    <Grid2 size={8} className="font-1-2vw">
                        <b>{title}</b>
                    </Grid2>
                    <Grid2 size={4} sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        color: "#0381c5",
                    }}>
                        {icon}
                    </Grid2>
                    <Grid2 size={12} className="font-normal" sx={{marginTop: "3vh"}}>
                        {texte}
                    </Grid2>
                </Grid2>
            </Container>
        </Card>
    )
}

// Personalised Image Card 1 props => title, description, destination (Link in learn more)
const ImgCard = (props) => {
    const description = (props.description).split('.')
    return (
        <Card sx={{ height: "100% !important", padding: "15px 25px", backgroundColor: "#271e3f", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <CardContent sx={{ height: "100% !important" }}>
                <Grid2 container spacing={2} sx={{  height: "100% !important" }}>
                    <Grid2 size={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Typography gutterBottom variant="h4" component="div" className="font-2vw" sx={{ marginTop: "15px" }}>
                            {props.title}
                        </Typography>
                        <Typography variant="h6" className="font-normal" component="div" sx={{ color: 'text.secondary', padding: "25px 0" }}>
                            {description[0]}.
                        </Typography>
                        <LinkedBtn fullWidth={true} sx={{ marginTop: "15px", justifyContent: "flex-start" }} endIcon={<ArrowForwardIcon />}  className="font-normal" text="Voir plus" destination={"/pages/"+props.destination} />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

// Personalised Image Card 1 props => title, description, destination (Link in learn more)
const ImgCard2 = (props) => {
    return (
        <Card sx={{ height: "100% !important", padding: "15px 25px", backgroundColor: "#271e3f", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <CardContent sx={{ height: "100% !important" }}>
                <Grid2 container spacing={2} sx={{  height: "100% !important" }}>
                    <Grid2 size={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Typography gutterBottom variant="h4" component="div" className="font-1-5vw" sx={{ marginTop: "15px" }}>
                            {props.title}
                        </Typography>
                        <Typography variant="h6" className="font-normal" component="div" sx={{ color: 'text.secondary', padding: "25px 0" }}>
                            {props.description}
                        </Typography>
                        <LinkedBtn fullWidth={true} endIcon={<ArrowForwardIcon />}  className="font-normal" text="Voir plus" destination={"/pages/"+props.destination} />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

// Our value

const Valeur = (props) => {
    return (
        <Card sx={{ backgroundColor: "#110f1c", height: "100%", padding: "2vw 2vh" }}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                <Grid2 container spacing={2}>
                    <Grid2 size={12} sx={{ display: "flex",alignItems: "center" }}>
                        {props.icon}
                    </Grid2>
                    <Grid2 size={12}>
                        <div
                            style={{ marginBottom: "15px", width: "100%", height: "5px", backgroundColor: "#1494d2"  }}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <h3><b className="font-1-5vw">{props.title}</b></h3>
                        <p className="font-normal">{props.paragraph}</p>
                    </Grid2>
                </Grid2>
            </Container>
        </Card>
    )
}

// The motivation
const Motivation = () => {
    return (
        <>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                <Grid2 container spacing={2} sx={{marginTop: "1.5vh"}}>
                    <Grid2 size={1}></Grid2>
                    <Grid2 size={10} className="ToFullSize">
                        <motion.div 
                            className="font-1-5vw"
                            variants={fadeIn("down", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "15vh"
                            }}
                        >
                            {HomeContent.motivation.intro}
                        </motion.div>
                    </Grid2>
                    <Grid2 size={1}></Grid2>

                    <Grid2 size={3} 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                        className="ToFullSize"
                    >
                        <motion.div
                            variants={fadeIn("right", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "1.5vh",
                                borderRight: "solid #2094d2 8px",
                                textAlign: "right",
                                paddingRight: "15px"
                            }}
                        >
                            <div
                                className="font-1-2vw"
                                style={{
                                    color: "#2094d2",
                                    fontWeight: "700"
                                }}
                            >
                                {HomeContent.motivation.paragraph[0][0]}
                            </div>
                            <div
                                className="font-normal"
                            >
                                {HomeContent.motivation.paragraph[0][1]}
                            </div>
                        </motion.div>
                        <motion.div
                            variants={fadeIn("right", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "1.5vh",
                                borderRight: "solid #8a62aa 8px",
                                textAlign: "right",
                                paddingRight: "15px"
                            }}
                        >
                            <div
                                className="font-1-2vw"
                                style={{
                                    color: "#8a62aa",
                                    fontWeight: "700"
                                }}
                            >
                                {HomeContent.motivation.paragraph[1][0]}
                            </div>
                            <div
                                className="font-normal"
                            >
                                {HomeContent.motivation.paragraph[1][1]}
                            </div>
                        </motion.div>
                    </Grid2>
                    <Grid2 size={6} className="ToFullSize">
                        <motion.div
                            variants={fadeIn("", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            >
                                <CardMedia
                                    className="imageLogoBig"
                                    component="img"
                                    alt="Logo"
                                    image={HomeContent.motivation.logo}
                                    sx={{ transition: '10s' }}
                                    id="logoToAnimate"
                                />
                            </motion.div>
                    </Grid2>
                    <Grid2 size={3}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}
                        className="ToFullSize"
                    >
                        <motion.div
                            variants={fadeIn("left", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "1.5vh",
                                borderLeft: "solid #5d52a3 8px",
                                textAlign: "left",
                                paddingLeft: "15px"
                            }}
                        >
                            <div
                                className="font-1-2vw"
                                style={{
                                    color: "#5d52a3",
                                    fontWeight: "700"
                                }}
                            >
                                {HomeContent.motivation.paragraph[2][0]}
                            </div>
                            <div
                                className="font-normal"
                            >
                                {HomeContent.motivation.paragraph[2][1]}
                            </div>
                        </motion.div>
                        <motion.div
                            variants={fadeIn("left", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "1.5vh",
                                borderLeft: "solid #ca2690 8px",
                                textAlign: "left",
                                paddingLeft: "15px"
                            }}
                        >
                            <div
                                className="font-1-2vw"
                                style={{
                                    color: "#ca2690",
                                    fontWeight: "700"
                                }}
                            >
                                {HomeContent.motivation.paragraph[3][0]}
                            </div>
                            <div
                                className="font-normal"
                            >
                                {HomeContent.motivation.paragraph[3][1]}
                            </div>
                        </motion.div>
                    </Grid2>
                </Grid2>
            </Container>
        </>
    )
}

// The Home component to export
const Home = () => {
    
    const csvData = useContext(CSVContext)

    const [loaded, setLoaded] = useState(false)
    const [loadedCSV, setLoadedCSV] = useState(false)
    const [data, setData] = useState(null)

    const anim = useRef()
    const animName = useRef()
    const animLogo = useRef()

    const settingData = (getData) => {
        setData(getData)
    }

    const setLoadedCSVData = () => {
        setLoadedCSV(true)
    }

    const setLoadedPage = () =>{
        setLoaded(true)
    }

    // The animation of the logo in the welcome section
    const animateLogo = (i = 0) => {
        try {
            clearTimeout(animLogo.current)
            if (i === 0) {
                document.getElementById("logoToAnimate").style.translate = "0 -5px"
                i++
                animLogo.current = setTimeout(animateLogo.bind(this, i), 8000)
            } else if (i === 1) {
                document.getElementById("logoToAnimate").style.translate = "0 0"
                i++
                animLogo.current = setTimeout(animateLogo.bind(this, i), 8000)
            } else {
                document.getElementById("logoToAnimate").style.translate = "0 5px"
                animLogo.current = setTimeout(animateLogo.bind(this, 0), 8000)
            }
        } catch (err) {clearTimeout(animLogo.current)}
    }

    // The animation of the Name
    const animateName = (i = 0, reverse = false) => {
        try {
            clearTimeout(animName.current)
            if (reverse){
                if (i > 0) {
                    document.getElementById("nameDiv").lastChild.remove()
                    i--
                    animName.current = setTimeout(animateName.bind(this, i, true), 500)
                } else {
                    animName.current = setTimeout(animateName.bind(this, 0), 500)
                }
            } else {
                if (i < HomeContent.welcome.name.length) {
                    const myNode = document.createElement("div")
                    const myTextNode = document.createTextNode(HomeContent.welcome.name[i].toUpperCase())
                    myNode.appendChild(myTextNode)
                    document.getElementById("nameDiv").appendChild(myNode)
                    i++
                    animName.current = setTimeout(animateName.bind(this, i), 300)
                } else {
                    animName.current = setTimeout(animateName.bind(this, i, true), 5000)
                }
            }
        } catch (err) {clearTimeout(animName.current)}
    }

    // The typing animation card
    const animateCard = (text = '', i = 0, reverse = false, y = 0) => {
        try {
            clearTimeout(anim.current)
            if (reverse) {
                if (i >= 0){
                    
                    text = text.slice(0, i)
                    document.getElementById("animatedText").innerText = text
                    i--
                    anim.current = setTimeout(animateCard.bind(this, text, i, true, y), 100)
                } else {
                    if (y < (HomeContent.welcome.animatedText.length -1)){
                        y++
                        anim.current = setTimeout(animateCard.bind(this, text, 0, false, y), 1000)
                    } else {
                        y=0
                        anim.current = setTimeout(animateCard.bind(this, text, 0, false, y), 1000)
                    }
                }
            } else {
                if (i < (HomeContent.welcome.animatedText[y].length)) {
                    text += HomeContent.welcome.animatedText[y][i]
                    document.getElementById("animatedText").innerText = text
                    i++
                    anim.current = setTimeout(animateCard.bind(this, text, i, false, y), 250)
                } else {
                    anim.current = setTimeout(animateCard.bind(this, text, i, true, y), 1000)
                }
            }
        } catch (err) {}
    }


    useEffect(() => {
        document.title = HomeContent.pageTitle
        if (!loaded) {
            window.scrollTo(0,0)
            setLoadedPage()
            anim.current = setTimeout(animateCard, 300)
            animName.current = setTimeout(animateName, 300)
            animLogo.current = setTimeout(animateLogo, 300)
        }
        if (!loadedCSV) {
            if (csvData) {
                const rows = csvData.map((row, index) => {
                    var img = null
                    try {
                        img = require("./../Images/other/"+row['Featured image'])
                    } catch (err) {}
                    row['imageFile'] = img
                    return { 
                        image: img,
                        title: row['Name'],
                        description: row['Project summary'],
                        link: row['Link']
                    }
                })

                settingData(rows)
                setLoadedCSVData()
            }
        }
    })

    return (
        <>
            <Navbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 0, paddingBottom: "100px", paddingTop: "150px" }} className="hanldeTop">
                <Grid2 container spacing={2}>
                    <Grid2 className="ToColumn" size={5} sx={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        <Grid2 className="ToFullSize" size={12} sx={{marginBottom: "1.0vh"}}>
                            <motion.div 
                                className="font-2vw"
                                variants={fadeIn("down", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                {HomeContent.welcome.title.toUpperCase()}
                            </motion.div>
                        </Grid2>
                        <Grid2 size={12}>
                            <hr />
                        </Grid2>
                        <Grid2 size={12} sx={{marginBottom: "1vh"}}>
                            <motion.div
                                className="font-14vw"
                                variants={fadeIn("right", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{
                                    fontWeight: 800,
                                    display: "flex"
                                }}
                                
                            >
                                <div style={{
                                    display: "flex"
                                }} id="nameDiv"></div><div style={{
                                    opacity: 0
                                }}>-</div>
                            </motion.div>
                        </Grid2>
                        <Grid2 size={12} sx={{marginBottom: "2vh"}}>
                            <motion.div
                                className="font-2vw"
                                variants={fadeIn("down", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                {HomeContent.welcome.slogant}
                            </motion.div>
                        </Grid2>
                        <Grid2 size={12} sx={{marginBottom: "1.5vh"}}>
                            <motion.div
                                className="font-1-5vw"
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                {HomeContent.welcome.subtext}
                            </motion.div>
                        </Grid2>
                        <Grid2 size={12} sx={{marginBottom: "2vh"}}>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                <Card style={{ backgroundColor: "#0a0622", paddingTop: "15px", paddingBottom: "15px" }} variant="outlined" sx={{ padding: "1vw" }} className="font-normal">
                                    <b> </b>
                                    <b
                                        id="animatedText"
                                        style={{
                                            paddingRight: "2px",
                                            transition: "0.2ms",
                                            borderRight: "solid 2px white"
                                        }}
                                    >   
                                    </b>
                                </Card>
                            </motion.div>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={2}></Grid2>
                    <Grid2 className="ToFullSize" size={5} >
                        <CardMedia
                            className="imageLogoBig"
                            component="img"
                            alt="Logo"
                            image={HomeContent.welcome.logo}
                            sx={{ transition: '10s' }}
                            id="logoToAnimate"
                        />
                    </Grid2>
                </Grid2>
            </Container>
            <div style={{
                marginTop: "5vh",
                position: "relative",
                background: "rgb(213 213 213)",
                left: "0",
                width: "100%",
                paddingTop: "15vh",
                paddingBottom: "20vh",
                marginBottom: "-5vh",
            }}>
                <Motivation />
            </div>

            <div style={{
                marginTop: "5vh",
                position: "relative",
                background: "#171425",
                left: "0",
                width: "100%",
                paddingTop: "5vh",
                paddingBottom: "20vh",
                marginBottom: "-5vh",
                color: "white"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                    <Grid2 container spacing={2} sx={{marginTop: "1.5vh"}} className="gridTo6">
                        <Grid2 size={12} sx={{ marginBottom: "25px", marginTop: "5vh" }}>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "15px", textAlign: "left", width: "100%" }} className="font-3vw"
                            >
                                { HomeContent.valeur.title }
                            </motion.div>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "20px", width: "100%" }} className="font-normal"
                            >
                                {HomeContent.valeur.subtitle}
                            </motion.div>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "15px", width: "10vw", height: "5px", backgroundColor: "#1494d2"  }}
                            />
                        </Grid2>
                        {
                            HomeContent.valeur.content.map((data, index) => {
                                return (
                                    <Grid2 className="ToFullSize" size={3} key={index}>
                                        <motion.div
                                            variants={fadeIn("up", 0.2)}
                                            initial="hidden"
                                            whileInView={"show"}
                                            viewport={{once: false, amount: 0.7}}
                                            style={{ height: "100%" }}
                                        >
                                            <Valeur title={data.title} icon={data.icon} paragraph={data.paragraph} />
                                        </motion.div>
                                    </Grid2>
                                )
                            })
                        }
                    </Grid2>
                </Container>
            </div>

            <div style={{ 
                position: "relative",
                background: "rgb(39 30 63)",
                left: "0",
                width: "100%",
                paddingTop: "2vh",
                paddingBottom: "20vh"
            }}>
                 <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                    <Grid2 container spacing={2} sx={{marginTop: "1.5vh"}}>
                        <Grid2 size={12} sx={{ marginBottom: "25px", marginTop: "5vh" }}>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "10px", width: "100%" }} className="font-3vw"
                            >
                                {HomeContent.service.title}
                            </motion.div>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "20px", width: "100%" }} className="font-normal"
                            >
                                {HomeContent.service.subtitle}
                            </motion.div>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "15px", width: "10vw", height: "5px", backgroundColor: "#1494d2"  }}
                            />
                        </Grid2>
                        {(data) ? data.map((row, index) => {
                            const pos = (index%3 === 0) ? "right" : "left"                            
                            
                            if (data.length%3 === 0) {
                                return (
                                    <Grid2 key={index} size={4} className="ToFullSize">
                                        <motion.div
                                            variants={fadeIn(pos, 0.2)}
                                            initial="hidden"
                                            whileInView={"show"}
                                            viewport={{once: false, amount: 0.7}}
                                            style={{height: '100%'}}
                                        >
                                            <ImgCard title={row.title} image={row.image} description={row.description} destination={row.link} />
                                        </motion.div>
                                    </Grid2>
                                )
                            } else {
                                if ((data.length - 2) > index) {
                                    return (
                                        <Grid2 key={index} size={4} className="ToFullSize">
                                            <motion.div
                                                variants={fadeIn(pos, 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                                style={{height: '100%'}}
                                            >
                                                <ImgCard title={row.title} image={row.image} description={row.description} destination={row.link} />
                                            </motion.div>
                                        </Grid2>
                                    )
                                } else {
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid2 size={6} className="ToFullSize">
                                                <motion.div
                                                    variants={fadeIn("up", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                    style={{height: '100%'}}
                                                >
                                                    <ImgCard2 title={row.title} image={row.image} description={row.description} destination={row.link} />
                                                </motion.div>
                                            </Grid2>
                                        </React.Fragment>
                                    )
                                }
                            }
                        }) : (<></>)}
                    </Grid2>
                </Container>
            </div>


            <div style={{ 
                position: "relative",
                marginTop: "-3vh",
                background: "white",
                left: "0",
                width: "100%",
                paddingTop: "5vh",
                paddingBottom: "20vh"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px", color: "black" }}>
                    <Grid2 container spacing={2}>
                        <div style={{
                            position: "absolute",
                            zIndex: "-100",
                            opacity: "0.05",
                        }}>
                            <img style={{ width: "100%"}} src={illustration} alt="" />
                        </div>
                        <Grid2 size={12} sx={{ marginBottom: "25px", marginTop: "10vh" }}>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "15px", textAlign: "left", width: "100%" }} className="font-3vw"
                            >
                                { HomeContent.rejoindre.title }
                            </motion.div>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "40px", width: "100%" }} className="font-normal"
                            >
                                {HomeContent.rejoindre.subtitle}
                            </motion.div>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ width: "100%", height: "5px", backgroundColor: "#1494d2" }}
                            />
                        </Grid2>
                        {
                            HomeContent.rejoindre.paragraph.map((data, index) => {
                                const dataTable = (data.split("."))


                                if (HomeContent.rejoindre.paragraph.length%2 === 0) {
                                    return (
                                        <>
                                        
                                        </>
                                    )
                                } else {
                                    if ((HomeContent.rejoindre.paragraph.length - 1) > index) {
                                        if (index%2 === 0) {
                                            return (
                                                <React.Fragment key={index}>
                                                    <Grid2 className="ToFullSize" size={5} sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center"
                                                    }}>
                                                        <motion.h2
                                                            variants={fadeIn("up", 0.2)}
                                                            initial="hidden"
                                                            whileInView={"show"}
                                                            viewport={{once: false, amount: 0.7}}
                                                            className="font-2vw"
                                                        >
                                                            {dataTable[0]}.
                                                        </motion.h2>
                                                        {
                                                            dataTable.map((data2, index2) => {
                                                                if (index2 === 0 || data2 === "") {
                                                                    return (<React.Fragment key={index2} />)
                                                                } else {
                                                                    return (
                                                                        <motion.p
                                                                            key={index2}
                                                                            variants={fadeIn("up", 0.2)}
                                                                            initial="hidden"
                                                                            whileInView={"show"}
                                                                            viewport={{once: false, amount: 0.7}}
                                                                            className="font-normal"
                                                                        >
                                                                            {data2}.
                                                                        </motion.p>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Grid2>
                                                    <Grid2 size={1}></Grid2>
                                                    <Grid2 size={5} className="ToFullSize" sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                        <CardMedia
                                                            className="idk"
                                                            component="img"
                                                            alt="Logo"
                                                            image={HomeContent.rejoindre.image[index]}
                                                            sx={{ width: "80%", height: "500px" }}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={1}></Grid2>
                                                </React.Fragment>
                                            )
                                        } else {
                                            return (
                                                <React.Fragment key={index}>
                                                    <Grid2 size={1}></Grid2>
                                                    <Grid2 size={5} className="ToFullSize" sx={{ display: "flex", justifyContent: "flex-start" }}>
                                                        <CardMedia
                                                            className="idk"
                                                            component="img"
                                                            alt="Logo"
                                                            image={HomeContent.rejoindre.image[index]}
                                                            sx={{ width: "80%", height: "500px" }}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={5} className="ToFullSize" sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center"
                                                    }}>
                                                        <motion.h2
                                                            variants={fadeIn("up", 0.2)}
                                                            initial="hidden"
                                                            whileInView={"show"}
                                                            viewport={{once: false, amount: 0.7}}
                                                            className="font-2vw"
                                                        >
                                                            {dataTable[0]}.
                                                        </motion.h2>
                                                        {
                                                            dataTable.map((data2, index2) => {
                                                                if (index2 === 0 || data2 === "") {
                                                                    return (<React.Fragment key={index2} />)
                                                                } else {
                                                                    return (
                                                                        <motion.p
                                                                            key={index2}
                                                                            variants={fadeIn("up", 0.2)}
                                                                            initial="hidden"
                                                                            whileInView={"show"}
                                                                            viewport={{once: false, amount: 0.7}}
                                                                            className="font-normal"
                                                                        >
                                                                            {data2}.
                                                                        </motion.p>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Grid2>
                                                    <Grid2 size={1}></Grid2>
                                                </React.Fragment>
                                            )
                                        }
                                    } else {
                                        return (
                                            <React.Fragment key={index}>
                                                <Grid2 size={1} />
                                                <Grid2 size={10} className="ToFullSize" sx={{ marginTop: "25px", marginBottom: "45px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <motion.h2
                                                        variants={fadeIn("up", 0.2)}
                                                        initial="hidden"
                                                        whileInView={"show"}
                                                        viewport={{ once: false, amount: 0.7 }}
                                                        style={{ textAlign: "center" }}
                                                        className="font-2vw"
                                                    >
                                                        {dataTable[0]}.
                                                    </motion.h2>
                                                    <motion.div
                                                        variants={fadeIn("up", 0.2)}
                                                        initial="hidden"
                                                        whileInView={"show"}
                                                        viewport={{once: false, amount: 0.7}}
                                                        style={{ width: "10%", height: "5px", backgroundColor: "#1494d2" }}
                                                    />
                                                </Grid2>
                                                <Grid2 size={1} />

                                                <Grid2 size={6}
                                                    className="ToFullSize"
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    {
                                                        dataTable.map((data2, index2) => {
                                                            if (index2 === 0 || data2 === "") {
                                                                return (<React.Fragment key={index2} />)
                                                            } else {
                                                                return (
                                                                    <motion.p
                                                                        key={index2}
                                                                        variants={fadeIn("up", 0.2)}
                                                                        initial="hidden"
                                                                        whileInView={"show"}
                                                                        viewport={{once: false, amount: 0.7}}
                                                                        className="font-normal"
                                                                    >
                                                                        {data2}.
                                                                    </motion.p>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </Grid2>
                                                <Grid2 size={1} />
                                                <Grid2 size={1} />
                                                <Grid2 size={4} className="ToFullSize">
                                                    <CardMedia
                                                        className="idk"
                                                        component="img"
                                                        alt="Logo"
                                                        image={HomeContent.rejoindre.image[index]}
                                                        sx={{ width: "100%", height: "100%" }}
                                                    />
                                                </Grid2>
                                            </React.Fragment>
                                        )
                                    }
                                }
                            })
                        }
                    </Grid2>
                </Container>
            </div>
            <div style={{ 
                position: "relative",
                background: "rgb(19 14 33)",
                left: "0",
                width: "100%",
                marginTop: "-5vh",
                paddingTop: "20vh",
                paddingBottom: "20vh"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                    <Grid2 container spacing={2} className="ToFullSize">
                        <Grid2 size={12} sx={{
                            marginBottom: "5vh"
                        }}>
                            <motion.h1
                            variants={fadeIn("up", 0.2)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            className="font-2vw" 
                            style={{textAlign:"left"}}
                            >
                                {HomeContent.outcome.title}
                            </motion.h1>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "20px", width: "100%" }} className="font-normal"
                            >
                                {HomeContent.outcome.description}
                            </motion.div>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ marginBottom: "15px", width: "10vw", height: "5px", backgroundColor: "#1494d2"  }}
                            />
                            
                        </Grid2>
                        {
                            (HomeContent.outcome.paragraph.map((data, index) => {
                                return (
                                    <Grid2 key={index} size={4} className="ToFullSize">
                                        {(index === 0) ? (
                                            <motion.div 
                                                variants={fadeIn("right", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                                style={{height: "100%"}}
                                            >
                                                {CardChoise(data.title, data.icon, data.texte)}
                                            </motion.div>
                                        ) : (
                                            (index === 1) ? (
                                                <motion.div 
                                                variants={fadeIn("up", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                                style={{height: "100%"}}
                                                >
                                                    {CardChoise(data.title, data.icon, data.texte)}
                                                </motion.div>
                                            ) : (
                                                <motion.div 
                                                variants={fadeIn("left", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                                style={{height: "100%"}}
                                                >
                                                    {CardChoise(data.title, data.icon, data.texte)}
                                                </motion.div>
                                            )
                                        )}
                                    </Grid2>
                                )
                            }))
                        }
                    </Grid2>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default Home