////// Library Importation ////

// MUI Component
import { CardMedia, Container, Grid2 } from '@mui/material'

// MUI Icon
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// Motion Animate and Personalised Animation
import { motion } from 'framer-motion'
import { fadeIn } from '../Components/variants'

// Footer Text and Icon Content
import FooterContent from '../Contents/Footer';

// Personalised Style Sheet
import './../Styles/Footer.css'
import './../Styles/Home.css'
import HomeContent from '../Contents/Home';
import { useContext } from 'react';
import { CSVContext } from '../Contexts/CSVContext';
import { Link } from 'react-router-dom';
import NavbarContent from '../Contents/Navbar';
import { IPPubContext } from '../Contexts/IPPubContext';

// Personalised Contact Container
const ContactDiv = (props) => {
    return (
        <>
            <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                <Grid2 className="ToFullSize" container spacing={2}>
                    <Grid2 size={3} sx={{display: "flex", justifyContent: "right", alignItems: "right"}}>
                        {props.icon}
                    </Grid2>
                    <Grid2 size={9}>
                        <div className="font-0-8vw">{props.title}</div>
                        <div className="font-normal">{props.data}</div>
                    </Grid2>
                </Grid2>
            </Container>
        </>
    )
}


// Footer component to export
const Footer = () => {

    const csvData = useContext(CSVContext)
    const cityData = useContext(IPPubContext)
    
    return (
        <>
            <div style={{ 
                background: "linear-gradient(45deg, #2c053f, #032033)",
                left: "0",
                width: "100%",
                marginTop: "-2vh",
                padding: "10vh 0"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                    <Grid2 className="ToColumn" container spacing={2}>
                        <Grid2 className="ToFullSize" size={4}>
                            <motion.div
                            variants={fadeIn("up", 0.2)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            >
                                <ContactDiv icon={<EmailIcon className="font-2-5vw" />} title="Email" data={FooterContent.contact.mail} />
                            </motion.div>
                        </Grid2>
                        <Grid2 size={4} />
                        <Grid2 className="ToFullSize" size={4}>
                            <motion.div
                                variants={fadeIn("left", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                            >
                                {
                                    (cityData) ? ( (cityData !== null) ? (
                                        (cityData.city.search("Toamasina") !== -1 || cityData.region.search("Toamasina") !== -1 || cityData.region.search("Atsinanana") !== -1) ? (
                                            <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.newLocation.toamasina} />
                                        ) : (
                                            <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.location} />
                                        )
                                    ) : (
                                        <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.location} />
                                    )) : (
                                        <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.location} 
                                    />)
                                }
                            </motion.div>
                        </Grid2>
                    </Grid2>
                </Container>
            </div>
            <div style={{ 
                position: "relative",
                background: "linear-gradient(54deg, rgb(39 8 55), rgb(3 31 49))",
                left: "0",
                width: "100%",
                marginTop: "-2vh",
                padding: "10vh 0 5vh 0"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                    <Grid2 className="ToColumn" container spacing={2}>
                        <Grid2 className="ToFullSize" size={4}>
                            <div>
                                <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                    <Grid2 className="ToFullSize" container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid2 size={12} sx={{paddingLeft: "2vw"}}>
                                        <motion.div
                                                className="font-1-5vw"
                                                variants={fadeIn("right", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    alt="Logo"
                                                    image={FooterContent.logo}
                                                    sx={{ width: "10vw" }}
                                                    className='footerLogoDiv'
                                                />
                                            </motion.div>
                                        </Grid2>
                                        <Grid2 size={12} sx={{paddingLeft: "2vw", marginTop: "2vh"}} >
                                        <motion.p
                                                className="font-normal"
                                                variants={fadeIn("right", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                {FooterContent.description}
                                            </motion.p>
                                        </Grid2>
                                    </Grid2>
                                </Container>
                            </div>
                        </Grid2>
                        <Grid2 className="ToFullSize" size={4}>
                            <div>
                                <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                    <Grid2 className="ToFullSize" container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid2 size={12} sx={{ paddingLeft: "2vw" }} className="font-1-5vw">
                                            <motion.h1
                                                className="font-1-5vw"
                                                variants={fadeIn("up", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                <b style={{color:'#0381c5'}}>{HomeContent.service.title.slice(0, 4)}</b><b>{HomeContent.service.title.slice(4)}</b>
                                            </motion.h1>
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <ul>
                                                {(csvData) ? (csvData.map((row, index) => {
                                                    return (<motion.li 
                                                        variants={fadeIn("up", 0.2)}
                                                        initial="hidden"
                                                        whileInView={"show"}
                                                        viewport={{once: false, amount: 0.7}}
                                                        key={index}>
                                                            <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={"/pages/"+row.Link}>{row.Name}</Link>
                                                        </motion.li>)
                                                })) : (<></>)}
                                            </ul>
                                        </Grid2>
                                    </Grid2>
                                </Container>
                            </div>
                        </Grid2>
                        <Grid2 className="ToFullSize" size={4}>
                            <div>
                                <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                    <Grid2 className="ToFullSize" container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid2 size={12} sx={{ paddingLeft: "2vw" }}>
                                            <motion.h1
                                                className="font-1-5vw"
                                                variants={fadeIn("left", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                <b style={{color:'#0381c5'}}>Info</b><b>rmation</b>
                                            </motion.h1>
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <ul>
                                                <motion.li
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={NavbarContent.logo.destination} >Accueil</Link>
                                                </motion.li>
                                                <motion.li
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={NavbarContent.contact.destination} >{NavbarContent.contact.text}</Link>
                                                </motion.li>
                                                <motion.li
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={NavbarContent.apropos.destination} >{NavbarContent.apropos.text}</Link>
                                                </motion.li>
                                            </ul>
                                        </Grid2>
                                    </Grid2>
                                </Container>
                            </div>
                        </Grid2>
                    </Grid2>
                </Container>
            </div>
            <div style={{ 
                position: "relative",
                left: "0",
                width: "100%",
                background: "black",
                padding: "2vh 5vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <motion.div
                    variants={fadeIn("right", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
                    className='font-normal'
                >
                    {FooterContent.copyright}
                </motion.div>
                <motion.div
                    variants={fadeIn("left", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
                    className='font-normal'
                >
                    <Link to={"/privacy"} style={{ color: "white" }}>Privacy policy - Terms of use</Link>
                </motion.div>
            </div>
        </>
    )
}

// Exportation of the footer
export default Footer