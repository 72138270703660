import { Container, Grid2 } from "@mui/material"
import Footer from "../Components/Footer"
import Navbar from "../Components/Navbar"
import policyContent from "../Contents/Policy"
import { motion } from "framer-motion"
import { fadeIn } from "../Components/variants"
import React, { useEffect } from "react"


const PrivacyPolicy = () => {
    
    useEffect(() => {
        document.title = policyContent.pageTitle
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Navbar />
            <div
                style={{
                    paddingBottom: "25vh",
                    paddingTop: "15vh",
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: "white",
                    color: "black"
                }}
            >
                <Container maxWidth="xl">
                    <Grid2 container spacing={2}>
                        <Grid2 size={1} />
                        <Grid2 size={11}>
                            <motion.h1
                                className="font-2vw"
                                variants={fadeIn("left", 0.05)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ textAlign: 'left' }}
                            >
                                {policyContent.title1}
                            </motion.h1>
                            <motion.div
                                variants={fadeIn("up", 0.05)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ width: "10%", height: "5px", marginBottom: "25px", backgroundColor: "#1494d2" }}
                            />
                        </Grid2>

                        {
                            Object.entries(policyContent.PrivacyPolicy).map(([key, data], index) => {
                                // console.log(typeof(data))
                                return (
                                    <React.Fragment key={index}>
                                        <Grid2 size={1} />
                                        <Grid2 size={10}>
                                            <motion.h1
                                                className="font-1-5vw"
                                                variants={fadeIn("left", 0, 0.05)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                                style={{ textAlign: 'left' }}
                                            >
                                                {key}
                                            </motion.h1>
                                            {
                                                (typeof(data) === "object") ? (
                                                    data.map((data2, index2) => {
                                                        return (
                                                            <motion.p
                                                                key={index2}
                                                                className="font-normal"
                                                                variants={fadeIn("left", 0, 0.05)}
                                                                initial="hidden"
                                                                whileInView={"show"}
                                                                viewport={{once: false, amount: 0.7}}
                                                                style={{ textAlign: 'left', paddingLeft: "25px" }}
                                                            >
                                                                - {data2}
                                                            </motion.p>
                                                        )
                                                    })
                                                ) : (
                                                    <motion.p
                                                        className="font-normal"
                                                        variants={fadeIn("left", 0.2)}
                                                        initial="hidden"
                                                        whileInView={"show"}
                                                        viewport={{once: false, amount: 0.7}}
                                                        style={{ textAlign: 'left', paddingLeft: "25px" }}
                                                    >
                                                        {data}
                                                    </motion.p>
                                                )
                                            }
                                        </Grid2>
                                        <Grid2 size={1} />
                                    </React.Fragment>
                                )
                            })
                        }
                        <Grid2 size={1} />
                        <Grid2 size={11} sx={{ marginTop: "10vh" }}>
                            <motion.h1
                                className="font-2vw"
                                variants={fadeIn("left", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ textAlign: 'left' }}
                            >
                                {policyContent.title2}
                            </motion.h1>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ width: "10%", height: "5px", marginBottom: "25px", backgroundColor: "#1494d2" }}
                            />
                        </Grid2>
                        {
                            Object.entries(policyContent.TermsOfUse).map(([key, data], index) => {
                                // console.log(typeof(data))
                                return (
                                    <React.Fragment key={index}>
                                        <Grid2 size={1} />
                                        <Grid2 size={10}>
                                            <motion.h1
                                                className="font-1-5vw"
                                                variants={fadeIn("left", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                                style={{ textAlign: 'left' }}
                                            >
                                                {key}
                                            </motion.h1>
                                            {
                                                (typeof(data) === "object") ? (
                                                    data.map((data2, index2) => {
                                                        return (
                                                            <motion.p
                                                                key={index2}
                                                                className="font-normal"
                                                                variants={fadeIn("left", 0.2)}
                                                                initial="hidden"
                                                                whileInView={"show"}
                                                                viewport={{once: false, amount: 0.7}}
                                                                style={{ textAlign: 'left', paddingLeft: "25px" }}
                                                            >
                                                                - {data2}
                                                            </motion.p>
                                                        )
                                                    })
                                                ) : (
                                                    <motion.p
                                                        className="font-normal"
                                                        variants={fadeIn("left", 0.2)}
                                                        initial="hidden"
                                                        whileInView={"show"}
                                                        viewport={{once: false, amount: 0.7}}
                                                        style={{ textAlign: 'left', paddingLeft: "25px" }}
                                                    >
                                                        {data}
                                                    </motion.p>
                                                )
                                            }
                                        </Grid2>
                                        <Grid2 size={1} />
                                    </React.Fragment>
                                )
                            })
                        }
                    </Grid2>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy