

const policyContent = {
    pageTitle: "Rezolys: Politique de Confidentialité et Conditions d’Utilisation",
    title1: "Privacy Policy (Politique de Confidentialité)",
    title2: "Terms of Use (Conditions d’Utilisation)",
    "PrivacyPolicy": {
        "1. Introduction": "Nous, chez Rezolys, accordons une grande importance à la protection de vos données personnelles. Cette politique de confidentialité explique quelles informations nous collectons, comment nous les utilisons et les droits dont vous disposez concernant vos données.",
        "2. Données collectées": [
            "Informations personnelles (nom, adresse e-mail, numéro de téléphone)",
            "Informations techniques (adresse IP, type de navigateur, pages visitées)",
            "Données recueillies via des cookies"
        ],
        "3. Utilisation des données": [
            "Fournir nos services",
            "Améliorer l'expérience utilisateur",
            "Communiquer avec vous (emails promotionnels, notifications importantes)",
            "Garantir la sécurité de nos systèmes"
        ],
        "4. Partage des données": "Vos données ne seront jamais vendues à des tiers. Nous pouvons toutefois partager certaines informations avec des partenaires de confiance pour fournir nos services ou respecter des obligations légales.",
        "5. Vos droits": "Vous avez le droit de : Accéder à vos données personnelles, Demander la correction ou la suppression de vos données, Vous opposer à leur traitement dans certains cas. Contactez-nous à contact@rezolys.com.",
        "6. Sécurité des données": "Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger vos données contre tout accès non autorisé, perte ou destruction.",
        "7. Modifications de la politique de confidentialité": "Nous nous réservons le droit de modifier cette politique de confidentialité. Toute modification sera affichée sur cette page avec une date de mise à jour."
    },
    "TermsOfUse": {
        "1. Acceptation des termes": "En accédant et en utilisant notre site web, vous acceptez de respecter ces conditions d'utilisation. Si vous n'êtes pas d'accord, veuillez ne pas utiliser nos services.",
        "2. Utilisation autorisée": [
            "Utiliser le site uniquement à des fins légales",
            "Ne pas tenter de perturber ou de compromettre la sécurité du site",
            "Respecter les droits de propriété intellectuelle de Rezolys"
        ],
        "3. Propriété intellectuelle": "Tous les contenus présents sur le site (textes, images, logos, vidéos) sont la propriété de Rezolys ou de ses partenaires. Toute reproduction, distribution ou modification sans autorisation est strictement interdite.",
        "4. Limitation de responsabilité": "Rezolys ne peut être tenu responsable des dommages résultant de l'utilisation ou de l'incapacité à utiliser le site, y compris en cas de pertes de données ou de virus.",
        "5. Modification des conditions": "Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Toute modification sera effective dès sa publication sur le site.",
        "6. Contact": "Pour toute question concernant ces conditions d'utilisation, contactez-nous à contact@rezolys.com."
    }
}

export default policyContent