import Navbar from "../Components/Navbar"
// import Footer from "../Components/Footer"
import { motion } from "framer-motion"
import { fadeIn } from "../Components/variants"
import { Alert, Card, CardContent, CardMedia, Checkbox, Container, FormControl, FormControlLabel, Grid2, InputLabel, LinearProgress, MenuItem, Select, Snackbar, TextField } from "@mui/material"
import FooterContent from "../Contents/Footer"
import ContactContent from "../Contents/Contact"
import './../Styles/Home.css'
import React, { useContext, useEffect, useRef, useState } from "react"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// MUI Icon
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeContent from "../Contents/Home"
import { Link } from "react-router-dom"
import NavbarContent from "../Contents/Navbar"
import { CSVContext } from "../Contexts/CSVContext"
import { IPPubContext } from "../Contexts/IPPubContext"
import axios from "axios"
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { LoadingButton } from "@mui/lab"


// Personalised Contact Container
const ContactDiv = (props) => {
    return (
        <>
            <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                <Grid2 className="ToFullSize" container spacing={2}>
                    <Grid2 size={3} sx={{display: "flex", justifyContent: "right", alignItems: "right"}}>
                        {props.icon}
                    </Grid2>
                    <Grid2 size={9}>
                        <div className="font-0-8vw">{props.title}</div>
                        <div className="font-normal">{props.data}</div>
                    </Grid2>
                </Grid2>
            </Container>
        </>
    )
}

const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });


const Contact = () => {

    const csvData = useContext(CSVContext)
    const cityData = useContext(IPPubContext)
    const [formulaire, SetFormulaire] = useState(null)
    const [captchaVerified, setCaptchaVerified] = useState(false)
    const recaptcha = useRef()
    const secteurOptions = [
        { value: 'technologie', text: 'Technologie et TIC' },
        { value: 'industrie', text: 'Industrie' },
        { value: 'construction', text: 'Construction et BTP' },
        { value: 'education', text: 'Éducation' },
        { value: 'banque', text: 'Banque et finance' },
        { value: 'administration', text: 'Administration publique' },
        { value: 'energie', text: 'Énergie et environnement' },
        { value: 'commerce', text: 'Commerce' },
        { value: 'tourisme', text: 'Tourisme' },
        { value: 'agriculture', text: 'Agriculture' },
        { value: 'elevage', text: 'Élevage' },
        { value: 'peche', text: 'Pêche' },
        { value: 'transport', text: 'Transport' },
        { value: 'mines', text: 'Mines et extractives' },
        { value: 'sante', text: 'Santé' },
        { value: 'autre', text: 'Autre' }
    ]
    
    const [nom, setNom] = useState('');
    const [captchaValue, setCaptchaValue] = useState('')
    const [prenoms, setPrenoms] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [entreprise, setEntreprise] = useState('');
    const [fonction, setFonction] = useState('');
    const [secteur, setSecteur] = useState('');
    const [secteurO, setSecteurO] = useState('');
    const [motif, setMotif] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Default SnackBar Rezolys')
    const [severity, setSeverity] = useState('success')

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    const handleCaptchaChange = (value) => {
        // console.log(value);
        setCaptchaVerified(!!value); // Set to true if value exists
        setCaptchaValue(value)
    };

    const handleEntreprise = (e) => {
        setEntreprise(e.target.value)
    }

    const handleMotif = (e) => {
        setMotif(e.target.value)
    }

    const handlePrenoms = (e) => {
        setPrenoms(e.target.value)
    }
    const handleNom = (e) => {
        console.log(captchaValue);
        
        setNom(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleFonction = (e) => {
        setFonction(e.target.value)
    }
    const handleSecteur = (e) => {
        setSecteur(e.target.value)
    }
    const handleSecteurO = (e) => {
        setSecteurO(e.target.value)
    }

    const resetAll = () => {
        setEntreprise('')
        setMotif('')
        setPrenoms('')
        setNom('')
        setEmail('')
        setFonction('')
        setSecteur('')
        setSecteurO('')
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        
        if (formulaire === "com") {
            const data = {
                source: cityData.ip,
                nom: nom,
                prenoms: prenoms,
                email: email,
                numero: phone,
                fonction: fonction,
                entreprise: entreprise,
                secteur: (secteur === "autre") ? secteurO : secteur,
                motif: motif,
                recaptcha: captchaValue
            }
            setLoading(true)
            await sendMail(data)
            setLoading(false)
            
        } else if (formulaire === "rh") {
            const data = {
                source: cityData.ip,
                nom: nom,
                prenoms: prenoms,
                email: email,
                numero: phone,
                motif: motif,
                recaptcha: captchaValue
            }
            setLoading(true)
            await sendMail(data)
            setLoading(false)
        }
    }

    const setRHForm = () => {
        SetFormulaire("rh")
    }

    const setComForm = () => {
        SetFormulaire("com")
    }

    const sendMail = async (data) => {
        if (formulaire === "rh") {
            try {
                const result = await axios.post("https://rezolys.com/api/sendToRH.php", data)
                if (result.status === 200) {
                    if (recaptcha.current) {
                        recaptcha.current.reset(); // Reset the widget on page load
                        setCaptchaVerified(false)
                        setSeverity("success")
                        setMessage("Votre demande a été envoyée avec succès !")
                        resetAll()
                        SetFormulaire('')
                        setOpen(true)
                    }
                }
            } catch (error) {
                setSeverity("error")
                if (error.status === 401) {
                    setCaptchaVerified(false)
                    setMessage("Veuillez completer le CAPTCHA ci dessous !")
                    setOpen(true)
                } else {
                    setMessage("Une erreur s'est produite, veuillez réessayer plus tard !")
                    setOpen(true)
                }
                setCaptchaVerified(false)
                if (recaptcha.current) {
                    recaptcha.current.reset(); // Reset the widget on page load
                }
            }
        } else if (formulaire === "com") {
            try {
                const result = await axios.post("https://rezolys.com/api/sendToCom.php", data)
                if (result.status === 200) {
                    if (recaptcha.current) {
                        recaptcha.current.reset(); // Reset the widget on page load
                        setCaptchaVerified(false)
                        setSeverity("success")
                        setMessage("Votre demande a été envoyée avec succès !")
                        resetAll()
                        SetFormulaire('')
                        setOpen(true)
                    }
                }
            } catch (error) {
                setSeverity("error")
                if (error.status === 401) {
                    setCaptchaVerified(false)
                    setMessage("Veuillez completer le CAPTCHA ci dessous !")
                    setOpen(true)
                } else {
                    setMessage("Une erreur s'est produite, veuillez réessayer plus tard !")
                    setOpen(true)
                }
                setCaptchaVerified(false)
                if (recaptcha.current) {
                    recaptcha.current.reset(); // Reset the widget on page load
                }
            }
        }
    }

    useEffect(() => {
        document.title = ContactContent.pageTitle
        window.scrollTo(0,0)
        if (recaptcha.current) {
            recaptcha.current.reset(); // Reset the widget on page load
          }
    }, [])

    return (
        <>
            <Navbar />
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
            {
                (loading) ? (
                    <div
                        style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            zIndex: "25000",
                            backgroundColor: "#00000000"
                        }}
                    >
                        <LinearProgress />
                    </div>
                ) : (<></>)
            }
            <div
                style={{
                    paddingTop: "15vh",
                    paddingBottom: "25vh",
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: "white",
                    color: "black"
                }}
                className="hanldeTop"
            >
                
                <Container maxWidth="xl" className="hanldeTop">
                    <Grid2 container spacing={2}>
                        <Grid2 size={12} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <motion.h2
                                className="font-3vw"
                                variants={fadeIn("left", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ textAlign: 'center' }}
                            >
                                { ContactContent.help.title }
                            </motion.h2>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ width: "10%", height: "5px", marginBottom: "25px", backgroundColor: "#1494d2" }}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                className="font-normal"
                                style={{ textAlign: "center", marginBottom: "15px" }}
                            >
                                { ContactContent.help.question }
                            </motion.div>
                        </Grid2>
                        <Grid2 size={3} />
                        <Grid2 size={6} className="ToFullSize" sx={{ display: "flex", justifyContent: "center" }}>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ width: "250px", height: "100px" }}
                            >
                                { (formulaire === "com") ? (<div style={{ position: "absolute" }}><CheckBoxIcon /></div>) : (<></>) }
                                <Card sx={{ width: "100%", height: "100%", padding: "15px", cursor: "pointer", background: (formulaire === "com") ? ("#90caf9") : "#ffffff" }} onClick={setComForm}>
                                    <CardContent className="font-normal" sx={{ display: "flex", alignItems: "center", color: "black", height: "100%", textAlign: "center", cursor: "pointer" }}>
                                        { ContactContent.help.contactCom }
                                    </CardContent>
                                </Card>
                            </motion.div>
                            <div style={{ width: "25px" }}></div>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ width: "250px", height: "100px" }}
                            >
                                { (formulaire === "rh") ? (<div style={{ position: "absolute" }}><CheckBoxIcon /></div>) : (<></>) }
                                <Card sx={{ width: "100%", height: "100%", padding: "15px", cursor: "pointer", background: (formulaire === "rh") ? ("#90caf9") : "#ffffff" }} onClick={setRHForm}>
                                    <CardContent className="font-normal" sx={{ display: "flex", alignItems: "center", color: "black", height: "100%", textAlign: "center", cursor: "pointer" }}>
                                        { ContactContent.help.contactRH }
                                    </CardContent>
                                </Card>
                            </motion.div>
                        </Grid2>
                        <Grid2 size={3} />
                        {
                            (formulaire !== "rh" && formulaire !== "com") ? (
                                <></>
                            ) : (
                                <>
                                    <Grid2 size={3} />
                                    <Grid2 size={6} className="ToFullSize" sx={{ paddingTop: "10vh" }}>
                                        <ThemeProvider theme={lightTheme}>
                                            <div style={{ width: "100%" }}>
                                                <form onSubmit={handleSubmit}>
                                                    <Grid2 container spacing={2}>
                                                        <Grid2 size={6}>
                                                            <TextField
                                                                name="prenoms"
                                                                className="font-normal" 
                                                                label={ContactContent.formulaire.prenoms} 
                                                                value={prenoms} 
                                                                onChange={handlePrenoms}
                                                                fullWidth
                                                            />
                                                        </Grid2>
                                                        <Grid2 size={6}>
                                                            <TextField
                                                                name="nom"
                                                                className="font-normal" 
                                                                label="Nom" 
                                                                value={nom} 
                                                                onChange={handleNom}
                                                                fullWidth
                                                            />
                                                        </Grid2>
                                                        <Grid2 size={6}>
                                                            <TextField
                                                                name="nom"
                                                                type="email"
                                                                className="font-normal" 
                                                                label="Adresse Email" 
                                                                value={email} 
                                                                onChange={handleEmail}
                                                                // error
                                                                fullWidth
                                                                required
                                                            />
                                                        </Grid2>
                                                        <Grid2 size={6}>
                                                            <PhoneInput
                                                                id="phone"
                                                                name="phone"
                                                                country={'mg'}
                                                                enableLongNumbers={true}
                                                                value={phone}
                                                                onChange={setPhone}
                                                                inputStyle={{ width: '100%' }}
                                                            />
                                                        </Grid2>
                                                        {
                                                            (formulaire === "com") ? (
                                                                <>
                                                                    <Grid2 size={12}>
                                                                        <TextField
                                                                            name="fonction"
                                                                            className="font-normal" 
                                                                            label="Fonction" 
                                                                            value={fonction} 
                                                                            onChange={handleFonction}
                                                                            fullWidth
                                                                            required
                                                                        />
                                                                    </Grid2>
                                                                    <Grid2 size={6}>
                                                                        <TextField
                                                                            name="entreprise"
                                                                            className="font-normal" 
                                                                            label="Nom de l'Entreprise" 
                                                                            value={entreprise} 
                                                                            onChange={handleEntreprise}
                                                                            fullWidth
                                                                            required
                                                                        />
                                                                    </Grid2>
                                                                    <Grid2 size={6}>
                                                                        <FormControl fullWidth >
                                                                            <InputLabel id="secteurLabel">Secteur d'activité *  </InputLabel>
                                                                            <Select
                                                                                labelId="secteurLabel"
                                                                                label="Secteur d'activité"
                                                                                value={secteur}
                                                                                onChange={handleSecteur}
                                                                                required
                                                                            >
                                                                                {
                                                                                    secteurOptions.map((data, index) => {
                                                                                        return (
                                                                                            <MenuItem key={index} value={data.value}>{data.text}</MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid2>
                                                                    {
                                                                        (secteur === "autre") ? (
                                                                            <Grid2 size={12}>
                                                                                <TextField
                                                                                    name="activiteAutre"
                                                                                    className="font-normal" 
                                                                                    label="Autre secteur d'activité" 
                                                                                    value={secteurO} 
                                                                                    onChange={handleSecteurO}
                                                                                    fullWidth
                                                                                    required
                                                                                />
                                                                            </Grid2>
                                                                        ) : (<></>)
                                                                    }
                                                                </>
                                                            ) : (<></>)
                                                        }
                                                        <Grid2 size={12}>
                                                            <TextField
                                                                label="Comment pouvons-nous vous aider ?"
                                                                className="font-normal"
                                                                multiline
                                                                rows={4}
                                                                value={motif}
                                                                onChange={handleMotif}
                                                                fullWidth
                                                                required
                                                            />
                                                        </Grid2>
                                                        <Grid2 size={12}>
                                                            <FormControlLabel control={<Checkbox required />} label={ContactContent.formulaire.communication} />
                                                        </Grid2>
                                                        <Grid2 size={12}>
                                                            <FormControlLabel control={<Checkbox required />} label={ContactContent.formulaire.politique} />
                                                        </Grid2>
                                                        <Grid2 size={12}>
                                                            <ReCAPTCHA ref={recaptcha} onChange={handleCaptchaChange} sitekey="6LcOP64qAAAAAPf_vGvYimGxIaFZu70RTpRpLjBV" />
                                                        </Grid2>
                                                        <Grid2 size={12}>
                                                            <LoadingButton
                                                                loading={loading}
                                                                loadingPosition="start"
                                                                color="primary"
                                                                startIcon={<SendIcon />}
                                                                variant="contained"
                                                                fullWidth
                                                                type="submit"
                                                                disabled={!captchaVerified} // Disable button if CAPTCHA not verified
                                                            >
                                                                Soumettre
                                                            </LoadingButton>
                                                        </Grid2>
                                                    </Grid2>
                                                </form>
                                            </div>
                                        </ThemeProvider>
                                    </Grid2>
                                    <Grid2 size={3} />
                                </>
                            )
                        }
                    </Grid2>
                </Container>
            </div>
            <div 
                style={{
                    marginTop: '-20vh',
                    paddingTop: "10vh",
                    paddingBottom: "15vh",
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: "white",
                    color: "black"
                }}
            >
                
                </div>
            <div
                style={{
                    minHeight: 'calc(100vh - 200px)',
                    marginTop: '5vh',
                    display: "flex",
                    alignItems: 'center'
                }}
            >
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={1}></Grid2>
                        <Grid2 size={4} className="ToFullSize" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <motion.div
                                variants={fadeIn("right", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{
                                    width: "100%",
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    alt="Images"
                                    image={ContactContent.image}
                                    sx={{ width: "100%" }}
                                />
                            </motion.div>
                        </Grid2>
                        <Grid2 size={1}></Grid2>
                        <Grid2 size={5} className="ToFullSize" sx={{ marginBottom: "25px", flexDirection: "column", display: "flex", justifyContent: "center" }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <motion.h1
                                    className="font-1-5vw"
                                    variants={fadeIn("left", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    style={{ width: '80%' }}
                                >
                                    {ContactContent['title']}
                                </motion.h1>
                                <motion.p
                                    className="font-normal"
                                    variants={fadeIn("left", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    style={{ width: '80%' }}
                                >
                                    {ContactContent['description']}
                                </motion.p>
                            </div>
                            <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                <Grid2 className="ToColumn" container spacing={2}>
                                    <Grid2 className="ToFullSize" size={12}>
                                        <motion.div
                                        variants={fadeIn("up", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        >
                                            <ContactDiv icon={<EmailIcon className="font-2-5vw" />} title="Email" data={FooterContent.contact.mail} />
                                        </motion.div>
                                    </Grid2>
                                    <Grid2 className="ToFullSize" size={12}>
                                        <motion.div
                                            variants={fadeIn("left", 0.2)}
                                            initial="hidden"
                                            whileInView={"show"}
                                            viewport={{once: false, amount: 0.7}}
                                        >
                                            {
                                                (cityData) ? ( (cityData !== null) ? (
                                                    (cityData.city.search("Toamasina") !== -1 || cityData.region.search("Toamasina") !== -1 || cityData.region.search("Atsinanana") !== -1) ? (
                                                        <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.newLocation.toamasina} />
                                                    ) : (
                                                        <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.location} />
                                                    )
                                                ) : (
                                                    <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.location} />
                                                )) : (
                                                    <ContactDiv icon={<LocationOnIcon className="font-2-5vw" />} title="Adresse" data={FooterContent.contact.location} 
                                                />)
                                            }
                                        </motion.div>
                                    </Grid2>
                                </Grid2>
                            </Container>
                        </Grid2>
                        <Grid2 size={1}></Grid2>
                    </Grid2>
                </Container>
            </div>
            <div style={{ 
                position: "relative",
                background: "linear-gradient(54deg, rgb(39 8 55), rgb(3 31 49))",
                left: "0",
                width: "100%",
                marginTop: "-2vh",
                padding: "10vh 0 5vh 0"
            }}>
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px" }}>
                    <Grid2 className="ToColumn" container spacing={2}>
                        <Grid2 className="ToFullSize" size={4}>
                            <div>
                                <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                    <Grid2 className="ToFullSize" container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid2 size={12} sx={{paddingLeft: "2vw"}}>
                                        <motion.div
                                                className="font-1-5vw"
                                                variants={fadeIn("right", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    alt="Logo"
                                                    image={FooterContent.logo}
                                                    sx={{ width: "10vw" }}
                                                    className='footerLogoDiv'
                                                />
                                            </motion.div>
                                        </Grid2>
                                        <Grid2 size={12} sx={{paddingLeft: "2vw", marginTop: "2vh"}} >
                                        <motion.p
                                                className="font-normal"
                                                variants={fadeIn("right", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                {FooterContent.description}
                                            </motion.p>
                                        </Grid2>
                                    </Grid2>
                                </Container>
                            </div>
                        </Grid2>
                        <Grid2 className="ToFullSize" size={4}>
                            <div>
                                <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                    <Grid2 className="ToFullSize" container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid2 size={12} sx={{ paddingLeft: "2vw" }} className="font-1-5vw">
                                            <motion.h1
                                                className="font-1-5vw"
                                                variants={fadeIn("up", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                {HomeContent.service.title}
                                            </motion.h1>
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <ul>
                                                {(csvData) ? (csvData.map((row, index) => {
                                                    return (<motion.li 
                                                        variants={fadeIn("up", 0.2)}
                                                        initial="hidden"
                                                        whileInView={"show"}
                                                        viewport={{once: false, amount: 0.7}}
                                                        key={index}>
                                                            <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={"/pages/"+row.Link}>{row.Name}</Link>
                                                        </motion.li>)
                                                })) : (<></>)}
                                            </ul>
                                        </Grid2>
                                    </Grid2>
                                </Container>
                            </div>
                        </Grid2>
                        <Grid2 className="ToFullSize" size={4}>
                            <div>
                                <Container className="ToFullSize" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                                    <Grid2 className="ToFullSize" container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid2 size={12} sx={{ paddingLeft: "2vw" }}>
                                            <motion.h1
                                                className="font-1-5vw"
                                                variants={fadeIn("left", 0.2)}
                                                initial="hidden"
                                                whileInView={"show"}
                                                viewport={{once: false, amount: 0.7}}
                                            >
                                                Information
                                            </motion.h1>
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <ul>
                                                <motion.li
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={NavbarContent.logo.destination} >Accueil</Link>
                                                </motion.li>
                                                <motion.li
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={NavbarContent.contact.destination} >{NavbarContent.contact.text}</Link>
                                                </motion.li>
                                                <motion.li
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    <Link className='font-normal' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }} to={NavbarContent.apropos.destination} >{NavbarContent.apropos.text}</Link>
                                                </motion.li>
                                            </ul>
                                        </Grid2>
                                    </Grid2>
                                </Container>
                            </div>
                        </Grid2>
                    </Grid2>
                </Container>
            </div>
            <div style={{ 
                position: "relative",
                left: "0",
                width: "100%",
                background: "black",
                padding: "2vh 5vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <motion.div
                    variants={fadeIn("right", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
                    className='font-normal'
                >
                    {FooterContent.copyright}
                </motion.div>
                <motion.div
                    variants={fadeIn("left", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
                    className='font-normal'
                >
                    <Link to={"/privacy"} style={{ color: "white" }}>Privacy policy - Terms of use</Link>
                </motion.div>
            </div>
        </>
    )
}

export default Contact