import BuildIcon from '@mui/icons-material/Build';
import SecurityIcon from '@mui/icons-material/Security';
import HandshakeIcon from '@mui/icons-material/Handshake';
import VerifiedIcon from '@mui/icons-material/Verified';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PlaceIcon from '@mui/icons-material/Place';

import logoImgOnly from "./../Images/svg/Icon-rezolys.svg"

// The Motivation for the entreprise
import logoLinked from "./../Images/svg/IconWithLink.svg"

// The join image, just change the link to the correct one
import joinImage from './../Images/other/ImageUtilie-01.jpg'
import joinImage2 from './../Images/other/client1.jpg'
import joinImage3 from './../Images/other/client3.jpg'

const HomeContent = {
    // The title of the pages
    pageTitle: "Rezolys: The network within your reach",

    // Welcome section
    welcome: {
        title: "CYBERSECURITE, AUDIT, INFRASTRUCTURES RESEAUX",
        name: "Rezolys",
        logo: logoImgOnly,
        slogant: "The network within your reach",
        subtext: "Bienvenue chez Rezolys, votre partenaire de confiance en intégration de solutions",
        // This is the text in the animated section, you can add as many paragraph, it's in this form ['paragraph 1','paragraph 2','paragraph n']
        animatedText: [
            "Systèmes et réseaux",
            "Cybersécurité",
            "Protection des infrastructures informatique",
            "Datacenter et virtualisation des infrastructures informatique",
            "Intégration de solutions téléphonie"
        ]
    },
    motivation: {
        intro: "En 2025, disposer d’une infrastructure réseau évolutive et sécurisée est indispensable pour les entreprises, afin de faire face à des menaces cybernétiques en constante évolution et de protéger leurs données tout en maintenant la continuité des opérations.",
        logo: logoLinked,
        paragraph: [
            ["Risque accru de cyberattaques et de violations de données", "Un manque de préparation en matière de cybersécurité peut entraîner une exposition accrue aux cybermenaces, compromettant ainsi la sécurité des informations sensibles."],
            ["Perturbation des opérations en cas d'incidents de sécurité", "En cas de cyberattaque, les entreprises peuvent faire face à des interruptions significatives, entraînant des retards et une inefficacité opérationnelle qui nuisent à la productivité."],
            ["Confiance des clients compromise", "Des failles de sécurité peuvent engendrer une perte de confiance de la part des clients, résultant en une baisse significative de la satisfaction et de la fidélité à la marque."],
            ["Difficulté à se conformer aux réglementations", "L'absence de mesures de cybersécurité robustes peut conduire à des non-conformités aux réglementations en matière de protection des données, exposant l'entreprise à des sanctions et à des conséquences juridiques."]
        ]
    },

    valeur: {
        title: "Notre valeur ajoutée",
        subtitle: "L'innovation et l'excellence au cœur de nos services.",
        content: [
            {
                title: "Votre partenaire privilégié",
                icon: <HandshakeIcon className="font-14vw" sx={{ color: "#1494d2" }} />,
                paragraph: "Flexibles et réactifs, nous adaptons nos solutions aux besoins de nos clients, en mobilisant toutes nos ressources pour atteindre l’excellence opérationnelle, relever des défis ambitieuxet dépasser vos attentes."
            }, {
                title: "Un partenaire de confiance",
                icon: <VerifiedIcon className="font-14vw" sx={{ color: "#1494d2" }} />,
                paragraph: "Nous abordons chaque projet avec une vision à long terme, en garantissant une transparence totale tout au long de son développement. Notre solidité financière nous permet de soutenir l’évolution de nos collaborateurs et de développer un modèle économique durable."
            },
            {
                title: "Le talent de nos collaborateurs, notre force",
                icon: <LightbulbIcon className="font-14vw" sx={{ color: "#1494d2" }} />,
                paragraph: "Nous favorisons un environnement propice à leur développement professionnel et sommes fiers d’être régulièrement classés parmi les entreprises les plus agréables à travailler."
            }, {
                title: "Présence globale avec une forte implication locale",
                icon: <PlaceIcon className="font-14vw" sx={{ color: "#1494d2" }} />,
                paragraph: "Nous établissons des connexions avec vous, où que vous soyez, grâce à notre équipe diversifiée et multilingue."
            }
        ]
    },

    // The service Section
    service: {
        title: "Nos domaines d’expertise",
        subtitle: "Des Solutions Personnalisées pour Protéger et Optimiser vos Infrastructures",
    },

    rejoindre: {
        title: "Rejoignez l'aventure Rezolys",
        subtitle: "Explorez un monde de solutions fiables et évolutives.",
        image: [joinImage, joinImage2, joinImage3],
        // You can add as many paragraph as possible, it's in this form ['paragraph 1','paragraph 2','paragraph n']
        paragraph: [
            "Chez Rezolys, nous nous engageons à transformer la manière dont les entreprises abordent leurs infrastructures IT. Que vous soyez une petite entreprise cherchant à améliorer la connectivité de votre réseau ou une grande organisation à la recherche de solutions avancées de cybersécurité, notre mission est de vous offrir des solutions sur mesure qui garantissent efficacité, fiabilité et sécurité. Nous mettons à votre disposition des experts dédiés qui travaillent main dans la main avec vous pour comprendre vos besoins et vous proposer des stratégies adaptées à votre réalité.",
            "L’aventure Rezolys, c’est avant tout un partenariat de confiance. Nous croyons fermement que l’innovation et la transformation digitale sont les clés pour rester compétitif dans le monde actuel. C’est pourquoi nous vous offrons une gamme complète de services, allant de l’audit des infrastructures IT à la mise en place de systèmes de communication via IP, en passant par la virtualisation des serveurs et la cybersécurité. Nous ne nous contentons pas de vous fournir des solutions techniques ; nous vous accompagnons tout au long de votre parcours de transformation digitale.",
            "En rejoignant Rezolys, vous faites partie d’une communauté d’entreprises visionnaires qui investissent dans l’avenir. Nous croyons en une approche proactive, où la prévention et la résilience sont primordiales pour garantir la continuité des activités, même face aux défis imprévus. Ensemble, nous bâtirons des infrastructures solides, connectées et sécurisées, prêtes à affronter les exigences du monde numérique de demain. Laissez-nous vous guider dans cette aventure vers un avenir plus performant et plus sécurisé."
        ]
    },

    // The final section
    outcome: {
        title: "Pourquoi choisir Rezolys ?",
        description: "Votre partenaire de confiance",
        paragraph: [
            {
                title: "Expertise et solutions sur mesure",
                icon: <BuildIcon className="font-2-5vw" />,
                texte: "Chez Rezolys, nous comprenons que chaque entreprise est unique. C’est pourquoi nous proposons des solutions adaptées à vos besoins spécifiques, que ce soit pour améliorer la performance de vos réseaux, renforcer la sécurité de vos infrastructures ou moderniser vos systèmes de communication. Nos experts certifiés travaillent avec vous pour comprendre vos défis et y apporter des réponses concrètes, innovantes et sur mesure."
            }, {
                title: "Engagement en matière de sécurité",
                icon: <SecurityIcon className="font-2-5vw" />,
                texte: "Dans un monde où les menaces cybernétiques évoluent constamment, la sécurité ne peut pas être laissée au hasard. Rezolys met un point d'honneur à sécuriser vos données et infrastructures. Nous adoptons une approche proactive, avec des systèmes de surveillance 24/7 et des plans de résilience élaborés pour garantir la continuité de vos activités en cas d’incidents. Votre tranquillité d’esprit est notre priorité."
            }, {
                title: "Partenaire de confiance à long terme",
                icon: <HandshakeIcon className="font-2-5vw" />,
                texte: "Plus qu’un simple prestataire de services IT, Rezolys se veut être un véritable partenaire à long terme. Nous vous accompagnons de l'audit initial à la maintenance continue de vos infrastructures, en passant par la mise en place et l'optimisation de vos systèmes. Notre objectif est de vous fournir des solutions évolutives qui grandissent avec votre entreprise, tout en vous offrant un support dédié à chaque étape."
            }
        ]
    }
}

export default HomeContent