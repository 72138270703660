// Importation of the logo textless
import logoTextless from './../Images/svg/Logo-rezolys-textless.svg'


const NavbarContent = {
    // Logo + Link to the home
    logo: {
        image: logoTextless,
        destination: "/"
    },
    // Apropos text + Link
    apropos: {
        text: "A propos",
        destination: "/apropos"
    },
    // Contact + Link
    contact: {
        text: "Contactez-nous",
        destination: "/contact"
    },
    // Service text
    service: {
        title: "Nos Domaines d'expertise",
    }
}

export default NavbarContent
