///// Importation /////

// React Component
import React, { createContext, useState, useEffect } from 'react';


import axios from 'axios';

// Create a context
export const IPPubContext = createContext();

// Composant Provider
export const IPPubProvider = ({ children }) => {
  const [ipPubData, setIpPubData] = useState(null);

  // Charge the CSV data
  useEffect(() => {
    axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=5530b46386084effac77e0cf37325f89')
    .then(response => {
        // console.log(response.data);
        setIpPubData({
            ip: response.data.ip,
            city: response.data.city,
            region: response.data.state_prov,
            country: response.data.country_name
        })
    })
    .catch(error => {
        axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=ed175a5d06854813b6dc1d6a1b35e93e')
        .then(response => {
            // console.log(response.data);
            setIpPubData({
                ip: response.data.ip,
                city: response.data.city,
                region: response.data.state_prov,
                country: response.data.country_name
            })
        })
        .catch(error => {
            return null
        });
    });
  }, []);

  return (
    <IPPubContext.Provider value={ipPubData}>
      {children}
    </IPPubContext.Provider>
  );
};
