///////// Library Importation ///////

// React Hook 
import React, { useContext, useEffect, useState } from "react"

// MUI Component
import { CardMedia, Container, Grid2 } from "@mui/material"

// Router parameters
import { useParams } from "react-router-dom"
import illustration from "./../Images/other/illustration-01.png"

// Personalised Component Importation 
import Navbar from "../Components/Navbar"
import Footer from "./../Components/Footer"

// Logo image only
import logoImgOnly from "./../Images/svg/Icon-rezolys.svg"
import imageTeste from "./../Images/other/missionPrincipale.jpg"
import imageTeste2 from "./../Images/other/plusDetails.jpg"

// Personalised animation
import { motion } from "framer-motion"
import { fadeIn } from "../Components/variants"

// Personalised Style
import './../Styles/Home.css'

// Context CSV for the data
import { CSVContext } from "../Contexts/CSVContext"
import HomeContent from "../Contents/Home"
import ViewContent from "../Contents/View"

// 404 page module
import NoPage from "./noPage"


// The motivation
const Motivation = () => {
    return (
        <>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                <Grid2 container spacing={2} sx={{marginTop: "1.5vh"}}>
                    <Grid2 size={1}></Grid2>
                    <Grid2 size={10} className="ToFullSize">
                        <motion.div 
                            className="font-1-5vw"
                            variants={fadeIn("down", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "15vh"
                            }}
                        >
                            {HomeContent.motivation.intro}
                        </motion.div>
                    </Grid2>
                    <Grid2 size={1}></Grid2>

                    <Grid2 size={3} 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}
                        className="ToFullSize"
                    >
                        <motion.div
                            variants={fadeIn("right", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "1.5vh",
                                borderRight: "solid #2094d2 8px",
                                textAlign: "right",
                                paddingRight: "15px"
                            }}
                        >
                            <div
                                className="font-1-2vw"
                                style={{
                                    color: "#2094d2",
                                    fontWeight: "700"
                                }}
                            >
                                {HomeContent.motivation.paragraph[0][0]}
                            </div>
                            <div
                                className="font-normal"
                            >
                                {HomeContent.motivation.paragraph[0][1]}
                            </div>
                        </motion.div>
                        <motion.div
                            variants={fadeIn("right", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "1.5vh",
                                borderRight: "solid #8a62aa 8px",
                                textAlign: "right",
                                paddingRight: "15px"
                            }}
                        >
                            <div
                                className="font-1-2vw"
                                style={{
                                    color: "#8a62aa",
                                    fontWeight: "700"
                                }}
                            >
                                {HomeContent.motivation.paragraph[1][0]}
                            </div>
                            <div
                                className="font-normal"
                            >
                                {HomeContent.motivation.paragraph[1][1]}
                            </div>
                        </motion.div>
                    </Grid2>
                    <Grid2 size={6} className="ToFullSize">
                        <motion.div
                            variants={fadeIn("", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            >
                                <CardMedia
                                    className="imageLogoBig"
                                    component="img"
                                    alt="Logo"
                                    image={HomeContent.motivation.logo}
                                    sx={{ transition: '10s' }}
                                    id="logoToAnimate"
                                />
                            </motion.div>
                    </Grid2>
                    <Grid2 size={3}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}
                        className="ToFullSize"
                    >
                        <motion.div
                            variants={fadeIn("left", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "1.5vh",
                                borderLeft: "solid #5d52a3 8px",
                                textAlign: "left",
                                paddingLeft: "15px"
                            }}
                        >
                            <div
                                className="font-1-2vw"
                                style={{
                                    color: "#5d52a3",
                                    fontWeight: "700"
                                }}
                            >
                                {HomeContent.motivation.paragraph[2][0]}
                            </div>
                            <div
                                className="font-normal"
                            >
                                {HomeContent.motivation.paragraph[2][1]}
                            </div>
                        </motion.div>
                        <motion.div
                            variants={fadeIn("left", 0.2)}
                            initial="hidden"
                            exit="hidden"
                            whileInView={"show"}
                            viewport={{once: false, amount: 0.7}}
                            style={{
                                color: "black",
                                marginBottom: "1.5vh",
                                borderLeft: "solid #ca2690 8px",
                                textAlign: "left",
                                paddingLeft: "15px"
                            }}
                        >
                            <div
                                className="font-1-2vw"
                                style={{
                                    color: "#ca2690",
                                    fontWeight: "700"
                                }}
                            >
                                {HomeContent.motivation.paragraph[3][0]}
                            </div>
                            <div
                                className="font-normal"
                            >
                                {HomeContent.motivation.paragraph[3][1]}
                            </div>
                        </motion.div>
                    </Grid2>
                </Grid2>
            </Container>
        </>
    )
}

const Loading = () => {
    return (
        <>
            <motion.div
                variants={fadeIn("", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}
                style={{
                    fontSize: '8vw',
                    position: "fixed",
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    fontFamily: 'cursive',
                    letterSpacing: '3.5px',
                    fontWeight: '800',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                Page is loading...
            </motion.div>
        </>
    )
}

const View = () => {
    // Data CSV Context
    const csvData = useContext(CSVContext)

    // The parameter in the route
    const { request } = useParams()

    // Set all the state
    const [data, setData] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [lastPage, setLastPage] = useState(null)

    // Function that change the state value
    const settingData = (getData) => {
        setData(getData)
    }
    const setTheLastPage = (getPage) => {
        setLastPage(getPage)
    }
    const isLoaded = () => {
        setLoaded(true)
    }
    
    // Load the requested data
    useEffect(() => {
        if (!loaded || lastPage !== request) {
            window.scrollTo(0,0)
            if (csvData) {
                const rows = csvData.map((row, index) => {
                    var img = null
                    try {
                        img = require("./../Images/other/"+row['Featured image'])
                    } catch (err) {}
                    row['imageFile'] = img
                    return {index: index, data: row.Link, all: row}
                })
                const index = rows.findIndex(obj => obj.data === request)
                if (index !== -1) {
                    document.title = "Rezolys Service: "+rows[index].all['Name']
                    settingData(rows[index].all)
                } else {
                    settingData(404)
                }
                isLoaded()
                setTheLastPage(request)
            }
        }
    });


    return (
        <>
            {
                (loaded) ? ((data === 404) ? (<NoPage />) : (<>
                    <>
                    <Navbar />
                    <div style={{ 
                        position: "relative",
                        marginBottom: "-5vh",
                        background: "white",
                        left: "0",
                        color: "black",
                        paddingTop: "15vh",
                        paddingBottom: "15vh"
                    }}>
                        <Container className="hanldeTop" maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                            <Grid2
                                container
                                spacing={2}
                                className="ToColumn"
                            >
                                {/* <Grid2 size={12} sx={{ position: "absolute", width: "100%", left: "0", opacity: "0.2", marginTop: "-5vh", height: "100%" }}>
                                    <img src={illustration1} style={{ width: "100%", marginTop: "1.5vh" }} alt="Illustration" />
                                </Grid2> */}
                                <Grid2 size={5} className="ToFullSize" sx={{textAlign: "left"}}>
                                    <motion.h1
                                        variants={fadeIn("left", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        className="font-2vw"
                                        style={{
                                            fontWeight: 'bold',
                                            marginTop: "1vh"
                                        }}
                                    >
                                        {data['Name']}
                                    </motion.h1>
                                    <motion.h2
                                        variants={fadeIn("left", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        className="font-normal"
                                        style={{
                                            marginBottom: "25px"
                                        }}
                                    >
                                        {data['Welcome word title']}
                                    </motion.h2>
                                    <motion.div
                                        variants={fadeIn("up", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        style={{ marginBottom: "15px", width: "10vw", height: "5px", backgroundColor: "#1494d2"  }}
                                    />
                                    <div style={{ padding: "25px 0" }}>
                                        {
                                            (data['Welcome word'].split(".")).map((dataM, index) => {
                                                if (dataM === "") {
                                                    return (<React.Fragment key={index} />)
                                                } else {
                                                    return (
                                                        <motion.p
                                                            key={index}
                                                            variants={fadeIn("left", 0.2)}
                                                            initial="hidden"
                                                            whileInView={"show"}
                                                            viewport={{once: false, amount: 0.7}}
                                                            className="font-normal"
                                                        >
                                                            {dataM}.
                                                        </motion.p>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </Grid2>
                                <Grid2 size={7} className="ToFullSize">
                                    <CardMedia
                                        component="img"
                                        alt="Image File"
                                        image={data['imageFile']}
                                        sx={{ width: "100%", border: "2px solid white", height: "100%" }}
                                    />
                                </Grid2>
                            </Grid2>
                        </Container>   
                    </div>
                    
                    {(request === "cybersecurity") ? (
                        <div style={{
                            marginTop: "5vh",
                            position: "relative",
                            background: "rgb(213 213 213)",
                            left: "0",
                            width: "100%",
                            paddingTop: "10vh",
                            paddingBottom: "10vh",
                            marginBottom: "-5vh",
                        }}>
                            <Motivation />
                        </div>
                    ) : (<></>)}
                    <Container maxWidth="xl" sx={{ mt: 4, mb: 0, paddingTop: "15vh", paddingBottom: "25vh" }}>
                        <Grid2 
                            container
                            spacing={2} 
                            className="ToColumn"
                        >
                            <div style={{
                                position: "absolute",
                                zIndex: "-100",
                                opacity: "0.05",
                                width: "100vw",
                                padding: "0 5vw",
                                left: "0"
                            }}>
                                <img style={{ width: "100%", marginTop: "-10vh" }} src={illustration} alt="" />
                            </div>
                            <Grid2 size={5} className="ToFullSize">
                                <CardMedia
                                    component="img"
                                    alt="Image File"
                                    image={imageTeste}
                                    sx={{ width: "100%", height: "100%", border: "2px solid white" }}
                                />
                            </Grid2>
                            <Grid2 size={1} />
                            <Grid2 size={6} className="ToFullSize"  sx={{ display: 'flex', justifyContent: 'center', flexDirection: "column", alignItems: "center" }}>
                                <motion.h2
                                    variants={fadeIn("left", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    className="font-2vw"
                                >
                                    { ViewContent.objectif }
                                </motion.h2>
                                <motion.div
                                    variants={fadeIn("up", 0.2)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: false, amount: 0.7}}
                                    style={{ width: "100%", height: "5px", marginBottom: "25px", backgroundColor: "#1494d2" }}
                                />
                                {
                                    (data['Main mission'].split(".")).map((dataM, index) => {
                                        if (dataM === "") {
                                            return (
                                                <React.Fragment key={index} />
                                            )
                                        } else {
                                            return (
                                                <motion.p
                                                    key={index}
                                                    className="font-normal"
                                                    variants={fadeIn("left", 0.2)}
                                                    initial="hidden"
                                                    whileInView={"show"}
                                                    viewport={{once: false, amount: 0.7}}
                                                >
                                                    {dataM}.
                                                </motion.p>
                                            )
                                        }
                                    })
                                }
                            </Grid2>
                        </Grid2>
                    </Container>
                    <div style={{ 
                        position: "relative",
                        background: "rgb(19 14 33)",
                        left: "0",
                        width: "100%",
                        marginTop: "-5vh",
                        paddingTop: "15vh",
                        paddingBottom: "25vh"
                    }}>
                        <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                            <Grid2 container spacing={2} className="ToFullSize">
                                <Grid2 size={5} className="ToFullSize" sx={{marginBottom: "-25px"}}>
                                    <motion.h1
                                        variants={fadeIn("up", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        className="font-2vw" 
                                        style={{textAlign:"left"}}
                                    >
                                        { ViewContent.plus }
                                    </motion.h1>
                                    <motion.div
                                        variants={fadeIn("up", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        style={{ width: "100%", height: "5px", marginBottom: "25px", backgroundColor: "#1494d2" }}
                                    />
                                    {
                                        (data['Project details'].split(".")).map((dataM, index) => {
                                            if (dataM === "") {
                                                return (<React.Fragment key={index} />)
                                            } else {
                                                return (
                                                    <motion.p
                                                        key={index}
                                                        className="font-normal ToFullSize"
                                                        variants={fadeIn("left", 0.2)}
                                                        initial="hidden"
                                                        whileInView={"show"}
                                                        viewport={{once: false, amount: 0.7}}
                                                    >
                                                        {dataM}.
                                                    </motion.p>
                                                )
                                            }
                                        })
                                    }
                                </Grid2>
                                <Grid2 size={1}></Grid2>
                                <Grid2 size={6} className="ToFullSize">
                                    <CardMedia
                                        component="img"
                                        alt="Image File"
                                        image={imageTeste2}
                                        sx={{ width: "100%", height: "100%", border: "2px solid white" }}
                                    />
                                </Grid2>
                            </Grid2>
                        </Container>
                    </div>
                    <div style={{ 
                        position: "relative",
                        marginTop: "-3vh",
                        background: "white",
                        left: "0",
                        width: "100%",
                        paddingTop: "18vh",
                        paddingBottom: "18vh"
                    }}>
                        <Container maxWidth="xl" sx={{ mt: 4, mb: 0, marginTop: "-50px", color: "black" }}>
                            <Grid2 container spacing={2}>
                                <Grid2 size={5} className="ToFullSize" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <CardMedia
                                        component="img"
                                        alt="Image"
                                        image={logoImgOnly}
                                        sx={{ width: "95%", border: "2px solid white", borderRadius: '15px' }}
                                    />
                                </Grid2>
                                <Grid2 size={2} />
                                <Grid2 size={5} className="ToFullSize" sx={{ marginBottom: "25px", flexDirection: "column", display: "flex", justifyContent: "center" }}>
                                    {
                                        (data['Project summary'].split(".")).map((dataM, index) => {
                                            if (dataM === "") {
                                                return (<React.Fragment key={index} />)
                                            } else {
                                                return (
                                                    <motion.p
                                                        key={index}
                                                        className="font-1-5vw ToFullSize"
                                                        variants={fadeIn("left", 0.2)}
                                                        initial="hidden"
                                                        whileInView={"show"}
                                                        viewport={{once: false, amount: 0.7}}
                                                    >
                                                        {dataM}.
                                                    </motion.p>
                                                )
                                            }
                                        })
                                    }
                                </Grid2>
                            </Grid2>
                        </Container>
                    </div>
                    <Footer />
                </>
                </>)) : (
                    <>
                        <Navbar />
                        <Loading />
                    </>
                )
            }
            
        </>
    )
}

export default View