import { Link } from 'react-router-dom'
import logo from './../Images/svg/Icon-rezolys.svg'

const ContactContent = {
    // The title of the page: in the navigator tab
    pageTitle: "Rezolys: Nous contacter",
    // Title showing in the page
    title: "Nous contacter directement ?",
    // The image in the left
    image: logo,
    // The description about us
    description: "Pour toute demande d’information ou pour discuter de vos besoins spécifiques, n’hésitez pas à nous contacter; notre équipe se tient à votre disposition pour vous accompagner.",
    help: {
        title: "Nous sommes là pour vous aider",
        question: "Sélectionner la raison de votre demande",
        contactRH: "Je souhaite contacter les RH",
        contactCom: "Je souhaite contacter le service commercial",
    },
    formulaire: {
        prenoms: "Prénoms",
        communication: "J'accepte de recevoir des communications pertinentes de Rezolys.",
        politique: <span>J'ai lu et j'accepte les informations sur le traitement des données conformément à la <Link to={"/privacy"}>politique de confidentialité</Link> de Rezolys.</span>
    }
}

export default ContactContent