import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer"
import { motion } from "framer-motion"
import { fadeIn } from "../Components/variants"
import { CardMedia, Container, Grid2 } from "@mui/material"
import './../Styles/Home.css'
import AproposContent from "../Contents/Apropos"
import { useEffect } from "react"
import React from "react"
import imageTeste from "../Images/other/client1.jpg"
import imageTeste2 from "../Images/other/client2.jpg"
import imageTeste3 from "../Images/other/client3.jpg"

const imageUtile = [
    imageTeste,
    imageTeste2,
    imageTeste3
]

const Apropos = () => {

    useEffect(() => {
        document.title = AproposContent.pageTitle
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Navbar />
            <div
                style={{
                    paddingBottom: "25vh",
                    paddingTop: "20vh",
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: "white",
                    color: "black"
                }}
                className="hanldeTop"
            >
                <Container maxWidth="xl" sx={{ mt: 4, mb: 0 }}>
                    <Grid2 container spacing={2} className="hanldeTop">
                        <Grid2 size={6} className="ToFullSize">
                            <CardMedia
                                component="img"
                                alt="Image"
                                image={imageUtile[0]}
                                sx={{ width: "100%", height: "100%" }}
                            />
                        </Grid2>
                        <Grid2 size={1} />
                        <Grid2 size={5} className="ToFullSize" sx={{ flexDirection: "column", display: "flex", alignItems: "flex-end", justifyContent: "center", marginBottom: "-25px" }}>
                            <motion.h1
                                className="font-2vw"
                                variants={fadeIn("left", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ textAlign: 'center' }}
                            >
                                {AproposContent['title']}
                            </motion.h1>
                            <motion.div
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                style={{ width: "10%", height: "5px", marginBottom: "25px", backgroundColor: "#1494d2" }}
                            />
                            <motion.p
                                variants={fadeIn("up", 0.2)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{once: false, amount: 0.7}}
                                className="font-normal"
                            >
                                { AproposContent.description[0] }
                            </motion.p>
                        </Grid2>
                    </Grid2>
                </Container>
            </div>

            <Container maxWidth="xl" sx={{ paddingTop: "15vh", paddingBottom: "20vh" }}>
                <Grid2 container spacing={2}>
                    <Grid2 size={5} className="ToFullSize" sx={{ flexDirection: "column", display: "flex", alignItems: "flex-end", justifyContent: "center", marginBottom: "-25px" }}>
                        {AproposContent.description.map((data, index) => {
                            if (index === 0) {
                                return (
                                    <React.Fragment key={index} />
                                )
                            } else {
                                return (
                                    <motion.p
                                        key={index}
                                        variants={fadeIn("up", 0.2)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: false, amount: 0.7}}
                                        className="font-normal"
                                    >
                                        { data }
                                    </motion.p>
                                )
                            }
                        })}
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={6} className="ToFullSize">
                        <CardMedia
                            component="img"
                            alt="Image"
                            image={imageUtile[1]}
                            sx={{ width: "100%", height: "100%" }}
                        />
                    </Grid2>
                </Grid2>
            </Container>
                
            <Footer />
        </>
    )
}

export default Apropos